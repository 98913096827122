
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import t from "@common/src/i18n/t";
const setting = namespace("setting");
const app = namespace("app");
@Component({})
export default class PermissionEdit extends Vue {
  @setting.Action getPcPermissionList;
  @setting.Action createRole;
  @setting.Action updateRole;
  @setting.Action getRoleDetail;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  get breadData() {
    return [
      { name: this.$t("permission.role-list"), path: "/setting/role" },
      {
        name: this.isAdd
          ? this.$t("v210831.new-role")
          : this.isView
          ? this.$t("v210831.view-roles")
          : this.$t("v210831.edit-role")
      }
    ];
  }
  get isAdd() {
    return this.$route.name === "permissionAdd";
  }
  get isView() {
    return this.$route.name === "permissionView";
  }
  permissionData: any = {
    roleName: ""
  };
  permissionDataRules: any = {
    roleName: [{ required: true, message: t("permission.name-empty-tips"), trigger: "blur" }]
  };
  activeTabName: string = "first";
  treeProps = {
    label: "resourceName"
  };
  treeLoading: boolean = false;
  pcPermList: any[] = [];
  appPermList: any[] = [];
  submitForm(formName) {
    (this.$refs[formName] as any).validate(valid => {
      if (valid) {
        if (this.isAdd) {
          this.addRole();
        } else {
          this.editRole();
        }
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  addRole() {
    this.btnStartLoading();
    this.createRole({
      roleName: this.permissionData.roleName,
      resourcesList: [...this.getFilterPcTree()]
    })
      .then(data => {
        this.$message.success(this.$t("base.success") as any);
        this.$router.push(`/setting/role`);
      })
      .finally(() => {
        this.btnStopLoading();
      });
  }
  editRole() {
    this.btnStartLoading();
    this.updateRole({
      resourcesList: [...this.getFilterPcTree()],
      roleCode: this.$route.params.id,
      roleName: this.permissionData.roleName
    })
      .then(data => {
        this.$message.success(this.$t("base.success") as any);
        this.$router.push(`/setting/role`);
      })
      .finally(() => {
        this.btnStopLoading();
      });
  }
  getFilterPcTree() {
    return (this.$refs["pcTree"] as any).getCheckedNodes(false, true);
  }
  viewFilterTree(orginalTree, selectedKetys) {
    // console.log(JSON.stringify(orginalTree), selectedKetys);
    const tree = orginalTree;
    let f = function(treeArr) {
      for (let index = 0; index < treeArr.length; index++) {
        const element = treeArr[index];
        if (selectedKetys.includes(element.resourceCode)) {
          element.disabled = true;
          if (element.children) {
            f(element.children);
          }
        } else {
          treeArr.splice(index, 1);
          index--;
        }
      }
    };
    f(tree);
    return tree;
  }
  created() {
    this.init();
  }
  async init() {
    this.treeLoading = true;
    let pcTreeData = await this.getPcPermissionList({
      resourceClass: "3",
      resourceParent: "op_root"
    });
    if (!this.isView) {
      this.pcPermList = pcTreeData.data.list;
    }
    if (!this.isAdd) {
      const rolePermDetail = await this.getRoleDetail({
        roleCode: this.$route.params.id
      });
      this.permissionData.roleName = rolePermDetail.data.roleName;
      let editListKeys = [];
      let viewListKeys = [];
      try {
        rolePermDetail.data.resourcesList.forEach(item => {
          // if (item.leafNode) {
          //   editListKeys.push(item.resourceCode);
          // }
          editListKeys.push(item.resourceCode);
          viewListKeys.push(item.resourceCode);
        });
      } catch (e) {
        console.log(e);
      }
      if (this.isView) {
        this.pcPermList = this.viewFilterTree(pcTreeData.data.list, viewListKeys);
      }
      (this.$refs["pcTree"] as any).setCheckedKeys(editListKeys, false);
    }
    this.treeLoading = false;
  }
  handleCheckChange() {}
}
